
$(function(){
    var aTop = $('.header-banniere').height()
    if($(this).scrollTop() >= aTop/2){
        $("header").removeClass('header__home-page')
    } else {
        $("header").addClass('header__home-page')
    }

    $(window).scroll(function(){
        if($(this).scrollTop() >= aTop/2){
            $("header").removeClass('header__home-page')
        } else {
            $("header").addClass('header__home-page')
        }
    });
});